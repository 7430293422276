<template>
	<goods-detail :is-edit="true" />
</template>

<script>
	import GoodsDetail from './components/detail'

	export default {
		name: 'EditForm',
		components: {
			GoodsDetail
		}
	}
</script>
